<template>
	<div>
	<ContentHeader title="Data Akun Keuangan"  />
     <div class="content-header">
		<div class="container-fluid">
			<div class="row justify-content-center">
	            <div class="col">
	            	<div class="card">
	            		<div class="card-header row d-flex align-items-center">
	            			<h3 class="card-title col-6">Data Akun Keuangan</h3>
	            			<div class="col-6 text-right">
	            				<router-link to="/tambah-data-akun-keuangan"><button class="btn btn-sm btn-info">Tambah</button></router-link>	
	            			</div>
	            		</div>
	            		<div class="card-body">
	            			<div class="row align-items-center">
	            				<div class="col-lg-2">
	            					<span>Jumlah Baris</span>
	            					<select class="custom-select form-control" v-model="jumlah_baris" @change="getAkunKeuangan">
		            					<option value="10">10</option>
		            					<option value="15">15</option>
		            					<option value="20">20</option>
		            					<option value="25">25</option>
		            					<option value="30">30</option>
		            					<option value="40">40</option>
		            					<option value="45">45</option>
		            					<option value="50">50</option>
		            				</select>
	            				</div>
	            				<div class="col-lg-2">
	            				</div>
	            				<div class="col-lg-8"></div>
	            			</div>
	            			<table class="table table-bordered table-hover mt-4">
							  <thead>
							    <tr>
							      <th scope="col" class="text-sm">KODE</th>
							      <th scope="col" class="text-sm">KELOMPOK AKUN</th>
							      <th scope="col" class="text-sm">POS AKUN</th>
							      <th scope="col" class="text-sm">NAMA</th>
							      <th scope="col" class="text-sm">SALDO NORMAL</th>
							      <th scope="col" class="text-sm">POSISI LAPORAN</th>
							      <th scope="col" class="text-sm text-center" v-if="cabang.jenis == 'pusat'">AKSI</th>
							    </tr>
							  </thead>
							  <tbody>
							    <tr v-for="row in akun_keuangan" :key="row.id">
							    	<td>{{ row.kode }}</td>
							    	<td>{{ row.nama_kelompok_akun }}</td>
							    	<td>{{ row.nama_pos_akun }}</td>
							    	<td>{{ row.nama }}</td>
							    	<td>{{ row.saldo_normal }}</td>
							    	<td>{{ row.posisi_laporan }}</td>
							    	<td class="text-sm" v-if="cabang.jenis == 'pusat'" align="center">
										<router-link :to="`/edit-data-akun-keuangan/${row.id}`" class="btn btn-outline-info text-sm text-center">Edit</router-link>
									</td>
							    </tr>
							  </tbody>
							</table>
							<p class="text-center mt-4" v-if="memuat_data">Memuat Data...</p>
							<!-- <p class="text-center mt-4">{{ cekData }}</p> -->
	            		</div>
	            		<div class="card-footer">
							<nav aria-label="...">
							  <ul class="pagination">
							    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.from}" @click="paginateData(pagination.prev_page_url)">
							      <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
							    </li>
							    <li class="page-item" :class="{ active: pagination.current_page == row.label }" @click="paginateData(row.url)" v-for="(row, index) in links" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
							    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.last_page}" @click="paginateData(pagination.next_page_url)">
							      <a class="page-link" href="#">Next</a>
							    </li>
							  </ul>
							</nav>
	            		</div>
	            	</div>
	            </div>
	        </div>
		</div>
	</div>
    </div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader'
import { ref, onMounted, computed } from 'vue'
import axios from 'axios'
import store from '@/store'

const akun_keuangan = ref([])
const memuat_data = ref(true)
const jumlah_baris = ref(10)
const links = ref([])
const pagination = ref([])
const cabang = computed(() => store.getters['auth/cabang'])

const getAkunKeuangan = async () => {
	akun_keuangan.value = []
	memuat_data.value = true
	
	let { data } = await axios.get(`api/akun_keuangan/get/${jumlah_baris.value}`)
	
	if (data != 'kosong') {
		memuat_data.value = false
		akun_keuangan.value = data.data	
		links.value = data.links
		links.value.splice(0, 1)
		links.value.splice(links.value.length-1, 1)
		pagination.value = data
	}

}

const paginateData = async (url) => {

	if (url != null) {
		akun_keuangan.value = []
		memuat_data.value = true

		let link_url = new URL(url)
		link_url = `${link_url.pathname}${link_url.search}`

		
		let { data } = await axios.get(`${link_url}`)

		
		if (data != 'kosong') {
			memuat_data.value = false
			akun_keuangan.value = data.data	
			links.value = data.links
			links.value.splice(0, 1)
			links.value.splice(links.value.length-1, 1)
			pagination.value = data
		}

		console.log(data)
	}
}

onMounted(() => {
	getAkunKeuangan()
})
</script>

<style>
	
</style>